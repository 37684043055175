<template>
  <lazy-hydrate when-visible>
    <base-slider>
      <base-slider-item v-for="category in categories" :key="category.id">
        <category-navigation-circle
          :key="category.id"
          :navigation="category"
          class="px-3 mb-5"
          hide-border
          :show-breadcrumbs="isShowBreadcrumbs"
        ></category-navigation-circle>
      </base-slider-item>
    </base-slider>
  </lazy-hydrate>
</template>

<script>
import BaseSlider from '~/components/base/slider/BaseSlider'
import BaseSliderItem from '~/components/base/slider/BaseSliderItem'
import CategoryNavigationCircle from '~/components/category-navigation/CategoryNavigationCircle'
export default {
  name: 'CmsElementCategoryCircleSlider',
  components: {
    CategoryNavigationCircle,
    BaseSliderItem,
    BaseSlider,
  },
  props: {
    content: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    categories() {
      return this.content.data?.elements || []
    },
    isShowBreadcrumbs() {
      return this.content.config?.showBreadcrumbs?.value || false
    },
  },
}
</script>
